import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const AddWhyChoose = () => {

    const [choose, setService] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch(`https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/choose`)
            .then((res) => res.json())
            .then((info) => setService(info));
    }, []);

    useEffect(() => {
        fetch(`https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/choose-items`)
            .then((res) => res.json())
            .then((info) => setItems(info));
    }, []);


    const handleChooseSection = (event) => {
        event.preventDefault();
        const choosesubHeading = event.target.choosesubHeading.value;
        const chooseHeading = event.target.chooseHeading.value;
        const chooseImg = event.target.chooseImg.value;


        const chooseSection = {
            choosesubHeading,
            chooseHeading,
            chooseImg,


        };

        const url = `https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/add-choose`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(chooseSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' Why Choose is Updated');
            });
    };

    const handleAddItem = (event) => {
        event.preventDefault();
        const chooseIcon = event.target.chooseIcon.value;
        const chooseTitle = event.target.chooseTitle.value;
        const chooseDetails = event.target.chooseDetails.value;

        const itemSection = {
            chooseIcon,
            chooseTitle,
            chooseDetails,
        };

        const url = `https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/add-choose-item`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(itemSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert(' Choose Item is Added');
            });
    };



    return (
        <>
            

            <section id="services" class="services-area mt-5 mb-5 fix" >
                <div class="container">
                    <div class="row">

                    </div>
                    <div class="row">

                        <div class="col-lg-8 col-md-12">
                            <h3> Update Why Choose Text </h3>
                            {
                                choose.length === 1 &&
                                <> {
                                    choose.map(s => <Link className='btn-start-btn' to={`/choosetext-edit/${s._id}`}>Update Choose</Link>)
                                }</>

                            }
                            {
                                choose.length === 0 &&
                                <form class="contact-form " onSubmit={handleChooseSection}>
                                    <div class="row">



                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="chooseHeading" placeholder="Your Heading" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="choosesubHeading" placeholder="Your Sub Heading" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="chooseImg" placeholder="Choose Image" required />
                                            </div>
                                        </div>
                                        <div class="slider-btn">
                                            <button class="btn-start-btn" data-animation="fadeInRight" data-delay=".8s"> Update Choose </button>
                                        </div>
                                    </div>

                                </form>
                            }

                            <h3 className='mt-5'>Add Choose item</h3>
                            <form class="contact-form" onSubmit={handleAddItem}>
                                <div class="row">

                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="chooseIcon" placeholder="Choose Icon" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="chooseTitle" placeholder="Choose Title" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 mb-3">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="chooseDetails" placeholder="Choose Details" required />
                                        </div>
                                    </div>


                                    <div class="slider-btn">
                                        <button class="btn-start-btn" data-animation="fadeInRight" data-delay=".8s"> Add Item </button>
                                    </div>
                                </div>

                            </form>



                        </div>



                        <section className="service-section section-gap bg-color-secondary section-doted-bg mt-5">
                            <div className="">



                                <div className="row pt-70">
                                    {
                                        items.map(c => <div className="col-lg-4 col-md-6 col-sm-12 pb-70">
                                            <div className="item-single">
                                                <div className="icon">
                                                <i><img src={c.chooseIcon} alt="icon_shape" /></i>
                                                    <div className="icon-bg mb-10 mt-20">
                                                        
                                                    </div>
                                                </div>
                                                <div className="item-content">
                                                    <Link to={`/choose-edit/${c._id}`} className='btn-start-btn mb-3 mt-3'>Edit Choose</Link>
                                                    <h4>{c.chooseTitle}</h4>
                                                    <p>{c.chooseDetails}</p>

                                                </div>
                                            </div>

                                        </div>)
                                    }






                                </div>
                            </div>
                        </section>

                    </div>
                </div>

            </section>

        </>
    );
};

export default AddWhyChoose;