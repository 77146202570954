import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";


const NavBar = () => {

  const [user] = useAuthState(auth);
  const handleSignout = () => {
    signOut(auth);
  }
  const [users, setUser] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);


  useEffect(() => {
    fetch(`https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setUser(info));
  }, []);




  return (


    <>

      <div className="web_traffic">
        <header className="header">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              {
                logo.map(l=><a className="navbar-brand" href="/"><figure className="mb-0 banner-logo"><img src={l.logo} alt className="img-fluid" /></figure></a>)
              }
              
              <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
                <span className="navbar-toggler-icon" />
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                 
                  <li className="nav-item">
                    <a className="nav-link" href="/">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about-sec">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#service-sec">Services</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#pricing-sec">Pricing</a>
                  </li>
                  
                  
                  <li className="nav-item">
                    <a className="nav-link" href="#contact-sec">Contact</a>
                  </li>
                </ul>
                <div className="last_list">


                  <div style={{ display: 'flex' }}>
                    {user ? (
                      <div style={{ marginRight: '10px' }}>
                        <a href="/dashboard" className="btn-start-btn">
                          Dashboard
                        </a>
                      </div>
                    ) : (
                      <div className="cta-btn">
                        <a href="/login" className="btn-start-btn">
                          Log in
                        </a>
                      </div>
                    )}
                    {users.map(
                      (u) =>
                        u.userEmail === user?.email &&
                        u.userRole === 'Admin' && (
                          <div className="cta-btn" key={u.userId}>
                            <Link to="/admin" className="btn-start-btn">
                              Admin
                            </Link>
                          </div>
                        )
                    )}
                  </div>



                </div>
              </div>
            </nav>
          </div>
        </header>
        {/* Search Form */}
        <div id="search" className>
          <span className="close">X</span>
          <form role="search" id="searchform" method="get">
            <input defaultValue name="q" type="search" placeholder="Type to Search" />
          </form>
        </div>

      </div>




    </>
  );
};

export default NavBar;
