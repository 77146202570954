import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const PricingSection = () => {

  const [pricing, setPricing] = useState([]);


  useEffect(() => {
    fetch(`https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/pricings`)
      .then((res) => res.json())
      .then((info) => setPricing(info));
  }, []);

  return (



    
    <>
      <section className="web_pricing-section" id="pricing-sec"  >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">

                <h5>Our Pricing</h5>
                <h2>Affordable Pricing Plans</h2>
              </div>
            </div>
          </div>
          <div className="row" >

            {
              pricing.map(b => b.packageName === 'Basic' && <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="price_content">
                  <h4>{b.packageName} Plan</h4>
                  <div className="counting">
                    <sup>$</sup>
                    <span className="text1">{b.packagePrice}</span>
                    <span className="text-size-18 mb-0 month"></span>
                  </div>

                  <ul className="list-unstyled">
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{b.packagePointOne}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{b.packagePointTwo}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{b.packagePointThree}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{b.packagePointFour}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{b.packagePointFive}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{b.packagePointSix}</li>

                  </ul>


                  <Link to={`/order-now/${b._id}`} className="btn-start-btn ">
                    {b.packageButtonText} <i className="fas fa-angle-double-right" />
                  </Link>
                </div>
              </div>)}

            {
              pricing.map(p => p.packageName === 'Premium' && <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="price_content price_content2">
                  <span className="text text-size-16">Recommended</span>
                  <h4>{p.packageName} Plan</h4>
                  <div className="counting">
                    <sup>$</sup>
                    <span className="text1">{p.packagePrice}</span>
                    <span className="text-size-18 mb-0 month"></span>
                  </div>

                  <ul className="list-unstyled">
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{p.packagePointOne}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{p.packagePointTwo}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{p.packagePointThree}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{p.packagePointFour}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{p.packagePointFive}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{p.packagePointSix}</li>
                  </ul>
                  <Link to={`/order-now/${p._id}`} className="btn-start-btn ">
                    {p.packageButtonText} <i className="fas fa-angle-double-right" />
                  </Link>
                </div>
              </div>)}


            {
              pricing.map(s => s.packageName === 'Standard' && <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="price_content">
                  <h4>{s.packageName} Plan</h4>
                  <div className="counting">
                    <sup>$</sup>
                    <span className="text1">{s.packagePrice}</span>
                    <span className="text-size-18 mb-0 month"></span>
                  </div>
                  
                  <ul className="list-unstyled">
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" /> {s.packagePointOne}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" /> {s.packagePointTwo}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{s.packagePointThree}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{s.packagePointFour}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{s.packagePointFive}</li>
                    <li className="text-size-18"><i className="circle fa-duotone fa-check" />{s.packagePointSix}</li>
                  </ul>
                  <Link to={`/order-now/${s._id}`} className="btn-start-btn ">
                    {s.packageButtonText} <i className="fas fa-angle-double-right" />
                  </Link>
                </div>
              </div>)}




          </div>
        </div>
      </section>


    </>
  );
};

export default PricingSection;