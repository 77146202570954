import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://newleadgenerationcloneone-03eb7718aeaa.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (
   
    

    <>
    

    <div className="web_traffic">
 
  {/* Search Form */}
  <div id="search" className> 
    <span className="close">X</span>
    <form role="search" id="searchform" method="get">
      <input defaultValue name="q" type="search" placeholder="Type to Search" />
    </form>
  </div>
  <section className="banner-section">
    <div className="container position-relative">

      {
        banners.map(b=> <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="banner_content">
            <h1>{b.bannerHeading}</h1>
            <p className="paragraph">{b.bannerDetails}</p>
            <a className="btn-start-btn" href={b.buttonOneLink}>{b.buttonOneText}</a>
            
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-md-left text-center">
          <div className="banner_wrapper position-relative">                        
            <figure className="banner-image mb-0">
              <img src={b.bannerImage} className="img-fluid" alt />
            </figure>
            <figure className="banner-boximage1 mb-0">
              <img src="./assets/images/banner-boximage1.png" className="img-fluid" alt />
            </figure>
            <figure className="banner-boximage2 mb-0">
              <img src="./assets/images/banner-boximage2.png" className="img-fluid" alt />
            </figure>
            <figure className="banner-boximage3 mb-0">
              <img src="./assets/images/banner-boximage3.png" className="img-fluid" alt />
            </figure>
          </div>
        </div>
      </div>)
      }
      
    </div>
    <a href="#dropdown" className="top-btn">
      <figure className="banner-dropdownimage mb-0">
        <img src="./assets/images/banner-dropdownimage.png" className="img-fluid" alt />
      </figure>
    </a>
  </section>
</div>

    
    
    </>
  );
};

export default Banner;
