// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3ablHftPsY3owxC7LH7YIPYmhN-8s4LY",
  authDomain: "newleadgenerationoneclone.firebaseapp.com",
  projectId: "newleadgenerationoneclone",
  storageBucket: "newleadgenerationoneclone.appspot.com",
  messagingSenderId: "786917564100",
  appId: "1:786917564100:web:658b7efe9c0bae46137391"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;