import React from 'react';
import { Link } from 'react-router-dom';

import banner from './Images/banner.png'
import logo from './Images/logo.png'
import about from './Images/about.png'
import service from './Images/service.png'
import counter from './Images/counter.png'
import team from './Images/teammembers.png'
import pricing from './Images/price.png'
import calltoaction from './Images/calltoaction.png'
import footerad from './Images/contact.png'
import footersocial from './Images/social-media.png'
import copyright from './Images/copyright.png'
import timeline from './Images/timeline.png'
import testimonial from './Images/testimonial.png'

import "./Setting.css";
import HeaderBottom from '../../components/HomePage/HeaderBottom';

const Setting = () => {

    return (
        <>
            <div className='setting-menu'>
                <section id="home" className="slider-area slider-four fix p-relative">
                    <div className="slider-active">
                        <div
                            className="single-slider slider-bg d-flex align-items-center"
                            style={{
                                background: "url(img/slider/slider_img_bg.png) no-repeat",
                                backgroundPosition: "center center",
                            }}
                        >
                            <div className='container align-items-center justify-content-center mt-5'>
                                <div className="container text-center ">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/edit-banner' className="link-black">
                                                    <img src={banner} alt="Banner" /> Banner Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/add-logo' className="link-black">
                                                    <img src={logo} alt="Logo" /> Logo Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/edit-about' className="link-black">
                                                    <img src={about} alt="About Us" /> About Us Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/edit-counter' className="link-black">
                                                    <img src={counter} alt="Counter" /> Counter Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/edit-choose' className="link-black">
                                                    <img src={counter} alt="Choose" /> Why Choose Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/edit-service' className="link-black">
                                                    <img src={service} alt="Service" /> Service Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/edit-testimonial' className="link-black">
                                                    <img src={testimonial} alt="Testimonial" /> Testimonial Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/add-pricing' className="link-black">
                                                    <img src={pricing} alt="Pricing" /> Pricing Setting
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/add-address-footer' className="link-black">
                                                    <img src={footerad} alt="Address" /> Company Address
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/add-social-footer' className="link-black">
                                                    <img src={footersocial} alt="Social" /> Bio & Social URL
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="single-card">
                                                <Link to='/add-copyright' className="link-black">
                                                    <img src={copyright} alt="Copyright" /> Footer CopyRight
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='admin-cards mt-5 container '></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Setting;
